'use client';

import React, {
    createContext,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useIntersection, useMeasure } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import SvgIcon from 'components/ui/SvgIcon';

import Slide from './Slide';

import styles from './Carousel.module.scss';

export const CarouselContext = createContext({
    activeIndices: [],
    setActiveIndices: () => {},
    hasOverflow: true,
    isBeginning: true,
    isEnd: false,
    scrollToIndex: () => {},
});

const Carousel = ({
    children,
    className,
    hasNavigation = true,
    hideScrollBars = false,
    navigationTheme = 'blue',
    slideClassName,
    containerClassName,
    autoScroll = true,
    inlineIntro,
}) => {
    const [scrollLeft, setScrollLeft] = useState(0);
    const [activeIndices, setActiveIndices] = useState([]);
    const [isInView, setIsInView] = useState(false);
    const [isInViewForKeys, setIsInViewForKeys] = useState(false);

    const ref = useRef(null);
    const containerRef = useRef(null);
    const trackRef = useRef(null);

    const [widthRef, { width: containerWidth }] = useMeasure();
    const trackWidth = trackRef?.current?.scrollWidth;
    const slideWith = trackWidth / children.length;

    const isBeginning = Math.floor(scrollLeft) === 0;
    const isEnd = Math.ceil(containerWidth + scrollLeft) >= trackWidth;

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const intersectionForKeys = useIntersection(ref, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const handleNavigation = useCallback(
        direction => {
            const scroll = {
                prev: slideWith * -1,
                next: slideWith,
            };

            containerRef.current.scrollBy({
                left: scroll[direction],
            });
        },
        [slideWith]
    );

    const handleContainerScroll = () => {
        setScrollLeft(containerRef?.current?.scrollLeft);
    };

    const scrollToIndex = index => {
        if (autoScroll) {
            const scrollLeft = index * slideWith;

            containerRef.current.scrollTo({
                left: scrollLeft,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        containerRef?.current && containerRef.current.scrollTo({ left: 0 });
    }, [containerRef]);

    useEffect(() => {
        if (intersection?.isIntersecting) {
            setIsInView(true);
        }
    }, [intersection]);

    useEffect(() => {
        setIsInViewForKeys(!!intersectionForKeys?.isIntersecting);
    }, [intersectionForKeys]);

    useEffect(() => {
        const handleKeyUp = event => {
            switch (event.key) {
                case 'ArrowLeft':
                    handleNavigation('prev');
                    break;
                case 'ArrowRight':
                    handleNavigation('next');
                    break;
                default:
                    break;
            }
        };

        if (isInViewForKeys) {
            window.addEventListener('keyup', handleKeyUp);
        } else {
            window.removeEventListener('keyup', handleKeyUp);
        }

        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [isInViewForKeys, handleNavigation]);

    useEffect(() => {
        const container = containerRef.current;

        container.addEventListener('scroll', handleContainerScroll);

        return () =>
            container.removeEventListener('scroll', handleContainerScroll);
    }, [containerRef]);

    return (
        <CarouselContext.Provider
            value={{
                activeIndices,
                isBeginning,
                isEnd,
                setActiveIndices,
                scrollToIndex,
            }}
        >
            <section
                ref={ref}
                className={cx(styles.root, className, {
                    [styles.isInView]: isInView,
                    [styles.hideScrollBars]: hideScrollBars,
                    [styles.isBeginning]: isBeginning,
                    [styles.inlineIntro]: inlineIntro,
                })}
            >
                <div
                    ref={containerRef}
                    className={cx(styles.container, containerClassName)}
                >
                    <div ref={widthRef}>
                        <ul ref={trackRef} className={styles.track}>
                            {React.Children.map(children, (child, index) => {
                                return (
                                    <Slide
                                        root={containerRef}
                                        key={child.key}
                                        index={index}
                                        className={cx(
                                            styles.slide,
                                            slideClassName
                                        )}
                                    >
                                        {React.cloneElement(child)}
                                    </Slide>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                {hasNavigation && (
                    <div
                        className={cx(
                            styles.navigation,
                            styles[navigationTheme]
                        )}
                    >
                        <Button
                            theme="none"
                            className={cx(styles.button, styles.prev)}
                            onClick={() => handleNavigation('prev')}
                            disabled={isBeginning}
                            gtm-label="previous"
                            aria-label="previous"
                        >
                            <SvgIcon
                                className={styles.icon}
                                type="chevronLeft"
                            />
                        </Button>
                        <Button
                            theme="none"
                            className={cx(styles.button, styles.next)}
                            onClick={() => handleNavigation('next')}
                            disabled={isEnd}
                            gtm-label="next"
                            aria-label="next"
                        >
                            <SvgIcon
                                className={styles.icon}
                                type="chevronRight"
                            />
                        </Button>
                    </div>
                )}
            </section>
        </CarouselContext.Provider>
    );
};

Carousel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    navigationTheme: PropTypes.oneOf(['blue', 'cream', 'orange']),
    slideClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    hasNavigation: PropTypes.bool,
    hideScrollBars: PropTypes.bool,
    autoScroll: PropTypes.bool,
    inlineIntro: PropTypes.bool,
};

export default Carousel;
